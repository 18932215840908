import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { DataType } from "../../services/CompanyService";
import { FilterCollection } from "../../services/FilterService";
import { TranslationService } from "../../services/TranslationService";
import AdvancedFilters, { AdvancedFiltersButton } from "../shared/components/AdvancedFilters";
import Dropdown from "../shared/components/Dropdown";
import Table, { TableHeaderWithFieldId } from "../shared/Table";
import TableContext from "../task/TableContext";
import InboxListContext from "./InboxListContext";
import ActivityService from "../../services/ActivityService";
import { ToastService } from "../shared/bootstrap/Toast";
import TabPositionContext from "../shared/TabPositionContext";
import { InboxTableItem, getInboxTableHeaders } from "./InboxTableItem";
import { ButtonTooltipIcon } from "../shared/components/ButtonTooltipIcon";
import { InboxContext } from "./InboxTableProvider";
import Switch from "react-switch";
import SearchInput from "../shared/components/SearchInput";

const READ_STATES = {
    ALL: '0',
    READ: '1',
    NOT_READ: '2',
    ONLY_ARCHIVED: '3'
};

export const InboxList = ({ quickfilter, loading }: { quickfilter: number; loading: boolean }) => {
    const [includeArchived, setIncludeArchived] = useState(false);
    const [readStatus, setReadStatus] = useState<string>(READ_STATES.NOT_READ);
    const { search } = useLocation();
    const filterQS = new URLSearchParams(search).get("filter") ?? "";
    const { translate } = TranslationService;
    const { reload, applySearch } = useContext(TableContext);
    const { setRequest, request, response } = useContext(InboxContext);
    const { getCounterInbox } = useContext(TabPositionContext);
    const { messageIds, messageAll, setMessageAll, setMessageIds } = useContext(InboxListContext);
    const responseIds: number[] = useMemo(() => response?.list ? response?.list.map((el: any) => el.ID) : [], [response?.list])

    const unCheckNonSelected = useCallback(
        () => {
            let messagesSelected: number[] = [];
            messageIds.forEach(message => {
                if (responseIds.includes(message)) {
                    messagesSelected.push(message)
                }
            });
            setMessageIds([...messagesSelected])
        },
        [messageIds, responseIds, setMessageIds],
    )

    const typeValues = useMemo(() => {
        const values = [
            { Value: translate.WhatsAppAll, Id: READ_STATES.ALL },
            { Value: translate.WhatsAppRead, Id: READ_STATES.READ },
            { Value: translate.WhatsAppNotRead, Id: READ_STATES.NOT_READ },
        ];
        if (includeArchived) {
            values.push({ Value: translate.Archived, Id: READ_STATES.ONLY_ARCHIVED });
        }
        return values;
    }, [includeArchived, translate]);

    const onApplySearch = (keyword: string) => {
        applySearch(keyword);
    };

    const getPagedMessageIds = () =>
        Array.from(document.getElementsByClassName("checkbox-inbox")).reduce<number[]>(
            (ids, element) => {
                const inputElement = element as HTMLInputElement;
                const invoiceId = inputElement.dataset["invoiceselected"];
                if (invoiceId) ids.push(parseInt(invoiceId));
                return ids;
            },
            []
        );

    const toggleOneCheckbox = (invoiceId: number, checked: boolean) => {
        setMessageIds(checked ? [...messageIds, invoiceId] : messageIds.filter(id => id !== invoiceId));
        setMessageAll(false);
    };

    const tableHeaders = useMemo(() => {
        const toggleAllCheckboxes = (event: ChangeEvent<HTMLInputElement>) => {
            const checked = event.target.checked;
            setMessageIds(checked ? getPagedMessageIds() : []);
            setMessageAll(checked);
        };
        const headers = getInboxTableHeaders(quickfilter);
        headers.unshift(new TableHeaderWithFieldId("Checkbox", () => (
            <input type="checkbox" onChange={toggleAllCheckboxes} checked={messageAll} />
        ), false, false, "checkbox", DataType.IOStatus, "w-30px"));
        return headers;
    }, [messageAll, setMessageAll, setMessageIds, quickfilter]);

    const handleArchiveMessages = async () => {
        const result = await ActivityService.setArchived({ ids: messageIds, archived: request?.archived ? '0' : '1' });
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
        } else {
            getCounterInbox();
            reload();
        }
    };

    const handleReadStatusChange = async (read: boolean) => {
        const result = await ActivityService.setRead({ ids: messageIds, read: read ? '1' : '0' });
        if (result instanceof Error) {
            ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
        } else {
            getCounterInbox();
            reload();
        }
    };

    const handleGetStatus = (status: string) => {
        setReadStatus(status);
        if (status === READ_STATES.ALL) {
            const { read, archived, ...restRequest } = request || {};
            setRequest(includeArchived ? { ...restRequest } : { ...restRequest, archived: false });
        } else if (status === READ_STATES.ONLY_ARCHIVED) {
            setRequest(prev => ({ ...prev, read: true, archived: true }));
        } else if (status === READ_STATES.NOT_READ && includeArchived) {
            const { read, archived, ...restRequest } = request || {};
            setRequest(({ ...restRequest, read: false }));
        } else {
            const { read, archived, ...restRequest } = request || {};
            setRequest(prev => includeArchived ?
                ({ ...prev, ...restRequest, read: status === READ_STATES.READ })
                : ({ ...prev, ...restRequest, read: status === READ_STATES.READ, archived: false }));
        }
    };

    const toggleArchived = () => {
        if (includeArchived) {
            setReadStatus(READ_STATES.NOT_READ);
            setRequest(prev => ({ ...prev, archived: false, read: false }));
        } else {
            const { read, archived, ...restRequest } = request || {};
            setReadStatus(READ_STATES.ALL);
            setRequest(restRequest);
        }
        setIncludeArchived(!includeArchived);
    };

    const buttonConfigs = [
        {
            title: translate.Archive,
            onClick: handleArchiveMessages,
            iconClass: "far fa-archive"
        },
        {
            title: translate.Unarchive,
            onClick: handleArchiveMessages,
            iconClass: "far fa-undo"
        },
        {
            title: translate.MarkAsRead,
            onClick: () => handleReadStatusChange(true),
            iconClass: "far fa-eye"
        },
        {
            title: translate.MarkAsNotRead,
            onClick: () => handleReadStatusChange(false),
            iconClass: "far fa-eye-slash"
        }
    ];

    useEffect(() => {
        if (readStatus === '2') {
            unCheckNonSelected()
        }
    }, [readStatus, unCheckNonSelected])


    return (
        <div className="container-fluid padding">
            <div className="card mh-100 p-0">
                <div className="genericHeader justify-content-between">
                    <div className="d-flex col-md-6 align-items-center">
                        <div className="col-md-1">
                            <AdvancedFiltersButton />
                        </div>
                        <div className="dropdown-wrapper">
                            <Dropdown
                                quickFilter
                                defaultValue={readStatus}
                                items={typeValues}
                                onChange={handleGetStatus}
                                value={readStatus}
                            />
                        </div>
                        <div className="d-flex col-md-4 justify-content-around align-items-center">
                            <label className="form-label align-items-center" style={{ display: 'contents' }}>{translate.FilterArchive}</label>
                            <Switch
                                uncheckedIcon={false}
                                checkedIcon={false}
                                checked={includeArchived}
                                onChange={toggleArchived}
                                height={24}
                                width={50}
                            />
                        </div>
                        <div className="d-flex col-md-4 justify-content-end gap-2">
                            {(messageIds.length > 0 || messageAll) && (
                                buttonConfigs.map((config, index) => (
                                    <ButtonTooltipIcon
                                        key={index}
                                        title={config.title}
                                        icon={`${config.iconClass} p-1`}
                                        onClick={config.onClick}
                                    />
                                ))
                            )}
                        </div>
                    </div>
                    <SearchInput onSearch={onApplySearch} autosearch={false} />
                </div>
                <div className="pt-0">
                    <AdvancedFilters defaultValue={filterQS} page={FilterCollection.ReportActivity} />
                </div>
                {loading ? (
                    <div className={`table-loader-loading-fade ${loading ? " background-fade min-vh-75" : ""}`}>
                        <i className="fas fa-spinner fa-pulse fa-8x"></i>
                    </div>
                ) : (
                    <div className="px-3 pb-4">
                        <Table
                            headers={tableHeaders}
                            item={InboxTableItem(quickfilter, toggleOneCheckbox, tableHeaders)}
                            stickyHeader
                        />
                    </div>
                )}
            </div>
        </div>

    );
};
