import { BrowserRouter } from "react-router-dom";
import Routes from "./pages/shared/Routes.tsx";
import { Application } from "./pages/shared/Application";
import { AuthenticationProvider } from "./pages/shared/AuthenticationContext";
import { ModalContainer } from "./pages/shared/bootstrap/Modal";
import { ToastProvider } from "./pages/shared/bootstrap/Toast";
import { FloatingPanel } from "./pages/shared/FloatingPanel";
import { NavigationHistoryProvider } from "./pages/shared/NavigationHistory";
import "./utils/ArrayExtensionMethods";

function App() {
    return (
        <Application>
            <AuthenticationProvider>
                <ToastProvider>
                    <BrowserRouter>
                        <NavigationHistoryProvider>
                            <Routes />
                            <ModalContainer />
                            <FloatingPanel
                                id="floating-window"
                                height={200}
                                width={400}
                                resizable={true}
                                titleBar={{
                                    title: "React App Window",
                                    buttons: { minimize: true, maximize: true, close: false },
                                }}
                            />
                        </NavigationHistoryProvider>
                    </BrowserRouter>
                </ToastProvider>
            </AuthenticationProvider>
        </Application>
    );
}

export default App;
