import { Dispatch, SetStateAction, useEffect, useState } from "react";
import ImportTemplateService from "../../../../../services/ImportTemplateService";
import Dropdown from "../../../../shared/components/Dropdown";
import { TextEditor } from "../../../../shared/components/Editors";
import { ImportTemplateListResponse } from "../../../../shared/entities/ImportTemplateListResponse";
import { TranslationService } from "../../../../../services/TranslationService";

const PrecedingProcesses = ({ data, model, setModel, importsList = undefined }: { data?: ImportTemplateListResponse.Item, model: Partial<ImportTemplateListResponse.Item>, setModel: Dispatch<SetStateAction<Partial<ImportTemplateListResponse.Item>>>, importsList?: ImportTemplateListResponse }) => {
    const [imports, setImports] = useState<{ value: number, text: string }[] | undefined>(importsList?.list.map(x => ({ value: x.ImportTemplateID, text: x.Name, key: x.ImportTemplateID })));
    const processPeriod = [{ value: "0", text: TranslationService.translate.IntraDay }, { value: "1", text: TranslationService.translate.Hourly }];
    const processPostAction = [{ value: "0", text: TranslationService.translate.Discard }, { value: "1", text: TranslationService.translate.Process }];
    const [predecesorToAdd, setPredecesorToAdd] = useState<ImportTemplateListResponse.ImportTemplatePredecessor>({ ImportTemplateID: data?.ImportTemplateID, IntraDay: true, Hours: 0, PostpneIntraDay: true, postponeDueAction: 0, PostponeHours: 0, ImportTemplatePredecessorID: 0, } as ImportTemplateListResponse.ImportTemplatePredecessor);    
    model.ImportTemplatePredecessors = model.ImportTemplatePredecessors ?? [];
    useEffect(() => {
        const getImports = async () => {
            const result = await ImportTemplateService.getList();
            if (result instanceof Error) {
                return;
            }
            setImports(result.list.map(x => ({ value: x.ImportTemplateID, text: x.Name, key: x.ImportTemplateID })));
        };
        if (importsList === undefined) {
            getImports();
        }
    }, [importsList]);

    const addPreprocessor = () => {
        predecesorToAdd.ImportTemplatePredecessing = { Name: imports?.find(x => x.value === predecesorToAdd.ImportTemplatePredecessingID)?.text! } as ImportTemplateListResponse.ImportTemplatePredecessing;
        const ImportTemplatePredecessors = model.ImportTemplatePredecessors ? [...model.ImportTemplatePredecessors] : [];
        ImportTemplatePredecessors.push({ ...predecesorToAdd });
        setModel(x => ({ ...x, ImportTemplatePredecessors }));
    };
    return (<>
        <h5>{TranslationService.translate.PrecedingProcesses}</h5>
        <table className="table shadow-hover">
            <thead>
                <tr>
                    <th>{TranslationService.translate.ProcessNoun}</th>
                    <th>{TranslationService.translate.Period}</th>
                    <th>{TranslationService.translate.MaximumWaitingTime}</th>
                    <th>{TranslationService.translate.Action}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {model.ImportTemplatePredecessors?.map((x, i) => {
                    return (<tr key={i} className="show-child-on-hover">
                        <td>{x.ImportTemplatePredecessing.Name}</td>
                        <td>{x.IntraDay ? TranslationService.translate.IntraDay : x.Hours + TranslationService.translate.Hour + "s"}</td>
                        <td>{x.PostpneIntraDay ? TranslationService.translate.IntraDay : x.PostponeHours + " " + TranslationService.translate.Hour.toLocaleLowerCase() + "s"}</td>
                        <td>{processPostAction[x.postponeDueAction].text}</td>
                        <td className="show-when-hovering-parent"><i className={"pointer fas fa-fw fa-trash text-danger"} onClick={() => setModel(x => ({ ...x, ImportTemplatePredecessors: x.ImportTemplatePredecessors?.filter((x,ii) => i !== ii)}))} /></td>
                    </tr>);
                })}
                <tr className="bb-0 dont-shadow">
                    <td className="border-bottom-0">
                        <Dropdown onChange={(value: number | undefined) => value && setPredecesorToAdd(x => ({ ...x, ImportTemplatePredecessingID: value }))} items={imports === undefined ? [] : imports} optionLabel={TranslationService.translate.Select} />
                    </td>
                    <td className="border-bottom-0">
                        <div className="row">
                            <div className={predecesorToAdd.IntraDay ? "" : "col"}>
                                <Dropdown onChange={(value: string) => setPredecesorToAdd(x => ({ ...x, IntraDay: value === "0" }))} items={processPeriod} />
                            </div>
                            <div className={predecesorToAdd.IntraDay ? "d-none" : "col"}>
                                {!predecesorToAdd.IntraDay && <>
                                    <TextEditor onChange={(value: string) => setPredecesorToAdd(x => ({ ...x, Hours: parseInt(value) }))} defaultValue="0" />
                                    {TranslationService.translate.Hour}s
                                </>}
                            </div>
                        </div>
                    </td>
                    <td className="border-bottom-0">
                        <div className="row">
                            <div className="col">
                                <Dropdown onChange={(value: string) => setPredecesorToAdd(x => ({ ...x, PostpneIntraDay: value === "0" }))} items={processPeriod} />
                            </div>
                            <div className="col">
                                {!predecesorToAdd.PostpneIntraDay && <>
                                    <TextEditor onChange={(value: string) => setPredecesorToAdd(x => ({ ...x, PostponeHours: parseInt(value) }))} defaultValue="0" />
                                    {TranslationService.translate.Hour}s
                                </>}
                            </div>
                        </div>
                    </td>
                    <td className="border-bottom-0"><Dropdown onChange={(value: string) => setPredecesorToAdd(x => ({ ...x, postponeDueAction: parseInt(value) }))} items={processPostAction} /></td>
                    <td className="border-bottom-0"><button className="btn btn-link" onClick={addPreprocessor}>{TranslationService.translate.Add}</button></td>
                </tr>
            </tbody>
        </table>
    </>);
};

export default PrecedingProcesses;