import { useEffect, useState } from "react";
import { EmailComposeModel, EmailTabProps } from "./EmailCompose";
import ActivityService from "../../../services/ActivityService";
import { ToastService } from "../../shared/bootstrap/Toast";
import { TranslationService } from "../../../services/TranslationService";
import Loading from "../../shared/components/Loading";

export const EmailTabPreview = ({ model, getFeature, setModel }: EmailTabProps) => {
    const previewState = useState<string>();
    const [preview, setPreview] = previewState;
    useEffect(() => {
        const btnLoadPrevMailClick = async () => {
            // if (!showPrevEmailBtn) { return content; }
            // setShowPrevEmailBtn(false);
            // const result = await ActivityService.getEmailContent({ id: prevMessageId!, noheader: "0" });
            // if (result instanceof Error) {
            //     showToast(translate.ErrorProcessingRequest, undefined, "danger");
            //     setShowPrevEmailBtn(true);
            //     return undefined;
            // }
            // updateEmail("replacebody")("0");
            // if (content.current.length === 0) {
            //     content.current = "<br/>";
            // }
            // const newEmailBody = content.current + "<hr />" + result.content;
            // updateEmailBody(newEmailBody);
            // return newEmailBody;
            return " ";
        };
        const loadPreview = async () => {
            if (preview !== undefined) {
                return;
            }
            const newEmailBody = await btnLoadPrevMailClick();
            if (!newEmailBody) {
                return;
            }
            const result = await ActivityService.previewEmailContent(model as EmailComposeModel);
            if (result instanceof Error) {
                ToastService.showToast(TranslationService.translate.ErrorProcessingRequest, undefined, "danger");
            }
            else {
                setPreview(result.preview);
            }
        };
        loadPreview();
    }, [model, preview, setPreview]);

    return <>
        <div className="tabBody">
            <div className="card pb-3 mb-5">
                {preview ? <div dangerouslySetInnerHTML={{ __html: preview }}></div> :
                    <Loading />}
            </div>
        </div>
    </>;
};