import React, { ReactElement, useEffect, useRef } from "react";
import CompanyService from "../../../services/CompanyService";
import { TranslationService } from "../../../services/TranslationService";
import { formatCurrency, formatInteger } from "../../../utils/FormatUtils";
import { Tooltip } from "bootstrap";
import { renderToStaticMarkup } from "react-dom/server";

export const DashboardClientSales = (props: { averageAgingPerson: string | undefined, averageAging: string, averagePeriodPerson: string | undefined, averagePeriod: string, totalAmount: number | undefined, currencyId?: number }) => {
    const { translate } = TranslationService;
    const { averageAgingPerson, averageAging, averagePeriodPerson, averagePeriod, totalAmount, currencyId = CompanyService.getDefaultCurrencyId() } = props;

    return (
        <div className="dashboard__sales row">
            <div className="col-4">
                <div className="dashboard__total card p-3 pointer show-child-on-hover dashboard-h-card">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p>{translate.TotalReceivables}</p>
                        </div>
                        <h2>{formatCurrency(totalAmount ?? 0, currencyId, formatInteger)}</h2>
                    </div>
                </div>
            </div>

            <div className="col-4">
                <div className="card p-3 pointer show-child-on-hover dashboard-h-card">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p className="dashboard__sales_header">{translate.DebtAvgAging}</p>
                            <TooltipComponent title={translate.DebtAgingTip}>
                                <h2><i className="ms-1 fal fa-question-circle show-when-hovering-parent"></i></h2>
                            </TooltipComponent>
                        </div>
                        <div className="d-flex align-items-center">
                            <h2>{(averageAgingPerson) + " " + translate.Days}<span className="mx-2"><small className="dashboard__sales_header dashboard_info_add">{"(" + TranslationService.translate.CompanyTotal + ": " + (averageAging) + ")"}</small></span></h2>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-4">
                <div className="card p-3 pointer show-child-on-hover dashboard-h-card">
                    <div className="card-body px-2">
                        <div className="card-header mb-1">
                            <p className="dashboard__sales_header">{translate.ReportAveragePayment}</p>
                            <TooltipComponent title={translate.AvaragePaymentsTip}>
                                <h2><i className="ms-1 fal fa-question-circle show-when-hovering-parent"></i></h2>
                            </TooltipComponent>
                        </div>
                        <div className="d-flex align-items-center">
                            <h2>{(averagePeriodPerson) + " " + translate.Days}<span className="mx-2"><small className="dashboard__sales_header dashboard_info_add">{"(" + TranslationService.translate.CompanyTotal + ": " + (averagePeriod) + ")"}</small></span></h2>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}


const TooltipComponent = (props: React.PropsWithChildren<{ title: string | JSX.Element | false, placement?: string, className?: string, html?: boolean, onShow?: () => void, onInserted?: () => void, hideDelay?: number }>) => {
    const elementsRef = useRef<(HTMLSpanElement | null)[]>([]);

    useEffect(() => {
        if (props.title === false) {
            return;
        }

        const options: Partial<Tooltip.Options> = {
            customClass: 'tooltipIntiza' + (typeof props.title === "string" ? '' : " bigTooltip"),
            delay: { "show": 300, "hide": props?.hideDelay || 500 },
            title: typeof props.title === "string" ? props.title : renderToStaticMarkup(props.title),
            html: typeof props.title !== "string" || Boolean(props.html),
            trigger: 'click',
        };

        const tooltips = elementsRef.current
            .filter(x => x !== null)
            .map(span => Tooltip.getOrCreateInstance(span!, options));

        const elements = elementsRef.current.filter(x => x !== null);


        const handleTooltipHide = (tooltip: Tooltip) => {
            tooltip.hide();
        };


        const handleClickOutside = (event: MouseEvent) => {
            elements.forEach((element, index) => {
                const tooltipInstance = tooltips[index];
                if (element && !element.contains(event.target as Node)) {
                    handleTooltipHide(tooltipInstance);
                }
            });
        };


        elements.forEach((element, index) => {
            const tooltipInstance = tooltips[index];
            element!.addEventListener("focusout", () => handleTooltipHide(tooltipInstance));
        });


        document.addEventListener("mousedown", handleClickOutside);

        if (props.onShow) {
            elements.forEach(x => x!.addEventListener("shown.bs.tooltip", props.onShow!));
        }
        if (props.onInserted) {
            elements.forEach(x => x!.addEventListener("inserted.bs.tooltip", props.onInserted!));
        }

        return () => {
            tooltips.forEach(tooltip => {
                tooltip.dispose();
            });
            if (props.onShow) {
                elements.forEach(x => x!.removeEventListener("shown.bs.tooltip", props.onShow!));
            }
            if (props.onInserted) {
                elements.forEach(x => x!.removeEventListener("inserted.bs.tooltip", props.onInserted!));
            }


            elements.forEach((element, index) => {
                const tooltipInstance = tooltips[index];
                element!.removeEventListener("focusout", () => handleTooltipHide(tooltipInstance));
            });


            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props.html, props.onShow, props.onInserted, props.title, props?.hideDelay]);

    if (!props.title) {
        return <>{props.children}</>;
    }

    if (typeof props.children === "string") {
        return <span ref={ref => elementsRef.current[0] = ref} data-bs-placement={props.placement} className={props.className}>{props.children}</span>;
    }

    const items = React.Children.map(props.children, (child, index) => {
        const assignRef = (ref: HTMLElement) => elementsRef.current[index] = ref;
        return React.cloneElement(child as ReactElement<any>, {
            ref: assignRef,
        });
    });

    return <>{items}</>;
};


export default DashboardClientSales;