import LayoutAlert from "./components/LayoutAlert";
import "./../../pages/mockup/mockupStyles.css";
import LayoutHeader from "./LayoutHeader";
import { useEffect, useState } from "react";
import { getCache } from "../../utils/PersistenceUtils";
import { CompanyAuth } from "../../entities/login/CompanyAuth";
import { TranslationService } from "../../services/TranslationService";
import CompanyService from "../../services/CompanyService";

const Layout: React.FC = () => {
    const [firstLoad, setFirstLoad] = useState(true);
    const customerField = CompanyService.getCustomerFieldName();
    const customersField = CompanyService.getCustomersFieldName();

    useEffect(() => {
        if (firstLoad) {
            if (customerField.length > 0 && customersField.length > 0) {
                const customerFieldName = getCache<CompanyAuth>("company_auth")?.customerFieldName || "";
                const customersFieldName = getCache<CompanyAuth>("company_auth")?.customersFieldName || "";
                TranslationService.setCurrentLanguageReplace(TranslationService.currentLanguage, customerFieldName, customersFieldName);
            }
            setFirstLoad(false);
        }
    }, [customerField.length, customersField.length, firstLoad])

    return (<>
        <LayoutAlert />
        {!firstLoad && <LayoutHeader />}
    </>);
};

export default Layout;