import React from "react";
import { TranslationService } from "../../../services/TranslationService";
import ModalService, { ModalParams } from "./Modal";

export type DefaultModalProps = {
    title?: string;
    message?: string | React.FC;
    acceptButtonLabel?: string;
    acceptButtonClassName?: string;
    onAcceptClick?: () => void;
    size?: ModalParams["size"];
    closeButtonLabel?: string;
}
const DefaultModal: React.FC<DefaultModalProps> = (props: DefaultModalProps) => {
    const { translate } = TranslationService;
    const Message = typeof props.message === "string" ? () => <span>{props.message}</span> : (props.message ?? (() => <div></div>));
    const closeTraduction = props.closeButtonLabel ?? translate.Close;
    return (
        <>
            {props.message &&
                <div className="modal-body">
                    <Message />
                </div>
            }
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{closeTraduction}</button>
                {props.acceptButtonLabel && props.onAcceptClick &&
                    <button type="button" className={props.acceptButtonClassName ?? "btn btn-primary"} onClick={() => { props.onAcceptClick && props.onAcceptClick(); ModalService.hideModal(); }}>{props.acceptButtonLabel}</button>}
            </div>
        </>
    );
};
export default DefaultModal;