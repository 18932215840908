import React, { useCallback, useEffect, useState } from "react";
import AuthenticationService from "../../services/AuthenticationService";
import { clearCache, getCache, setCache } from "../../utils/PersistenceUtils";
// import LogonModal from "../account/LogonModal";
import Loading from "./components/Loading";
import ModalService from "./bootstrap/Modal";
import FloatingPanelService from "./FloatingPanel";

export const AUTHENTICATION_DATA_STORAGE_KEY = "authentication_data";

export interface IAuthenticationData {
    token: string;
    expires: number;
    refreshToken: string;
    refreshExpires: number;
}

type IAuthenticationContext = {
    setCurrentAuthentication: (value: IAuthenticationData) => void,
    stopExpirationTimeout: () => void,
    isAuthenticated: boolean,
    doLogout: () => void,
}

export let setAuthentication = (value: IAuthenticationData) => window.clearImmediate(undefined);
const AuthenticationContext = React.createContext<IAuthenticationContext>({} as IAuthenticationContext);
let sessionExpirationTimeout: number | undefined;
const AuthenticationProvider = (props: React.PropsWithChildren<object>) => {
    const [authStatus, setAuthStatus] = useState<"Needs initalization" | "Not logged in" | "Expired" | "Authenticated">("Needs initalization");

    function stopExpirationTimeout(): void {
        clearTimeout(sessionExpirationTimeout);
    }

    function setCurrentAuthentication(value: IAuthenticationData) {
        setCache(AUTHENTICATION_DATA_STORAGE_KEY, value);
        const expirationTime = getExpirationTimeMs(value?.refreshExpires);
        setupExpiration();
        setAuthStatus(expirationTime > 0 ? "Authenticated" : "Expired");
    }

    function getExpirationTimeMs(expirationTime: number | undefined) {
        return (expirationTime ?? 0) * 1000 - Date.now();
    }

    const setupExpiration = useCallback(() => {
        clearTimeout(sessionExpirationTimeout);
        sessionExpirationTimeout = setTimeout(() => {
            const authenticationData = getCache<IAuthenticationData>(AUTHENTICATION_DATA_STORAGE_KEY);
            const expirationTime = getExpirationTimeMs(authenticationData?.refreshExpires);
            if (authenticationData && window.location.href.includes("localhost:3000") && expirationTime < 60_000) {
                AuthenticationService.refreshToken({ refreshToken: authenticationData });
                return;
            }
            if (expirationTime < 0) {
                setAuthStatus("Expired");
            } else {
                setupExpiration();
            }
        }, 1000 * 5, {});
    }, []);

    function doLogout(): void {
        clearCache();
        stopExpirationTimeout();
        setAuthStatus("Not logged in");
        ModalService.hideModal();
        FloatingPanelService.hidePanel();
    }

    const value: IAuthenticationContext = {
        setCurrentAuthentication,
        stopExpirationTimeout,
        isAuthenticated: authStatus !== "Not logged in",
        doLogout,
    };

    useEffect(() => {
        AuthenticationService.getAuthenticationToken().then(() => {
            const authenticationData = getCache<IAuthenticationData>(AUTHENTICATION_DATA_STORAGE_KEY);
            const expirationTime = getExpirationTimeMs(authenticationData?.refreshExpires);
            if (expirationTime > 0) {
                setupExpiration();
            }
            setAuthStatus(expirationTime > 0 ? "Authenticated" : "Not logged in");
        });
    }, [setupExpiration]);

    useEffect(() => {
        setAuthentication = setCurrentAuthentication;
    });

    return (
        <AuthenticationContext.Provider value={value}>
            {/* <LogonModal expired={authStatus === "Expired"} /> */}
            {(authStatus === "Needs initalization") ? <Loading /> : props.children}
        </AuthenticationContext.Provider>
    );
};

export { AuthenticationProvider };
export default AuthenticationContext;