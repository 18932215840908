import { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import FileService from '../../services/FileService';
import { TranslationService } from '../../services/TranslationService';

export const FileDownloadReport = () => {
    const history = useHistory();

    const fileId = new URLSearchParams(history.location.search).get("id") ?? "";

    useEffect(() => {
        if (fileId) {
            FileService.downloadReport(fileId)
                .finally(() => history.push(`${TranslationService.currentLanguage}/company`))
        }
    }, [fileId, history])


    return (
        <div></div>
    )
}
