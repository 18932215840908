import { TableHeader, default as Table } from "../shared/Table";
import TabPosition from "../shared/TabPosition";
import { useCallback, useMemo } from "react";
import ClientTableProvider from "../client/providers/ClientTableProvider";
import ClientTableTotal from "../client/components/ClientTableTotal";
import ClientTableItem from "../client/components/ClientTableItem";
import { TranslationService } from "../../services/TranslationService";
import { useHistory, useLocation } from "react-router-dom";
import { SegmentListResponse } from "../../entities/segment/SegmentListResponse";
import QueryString from "qs";
import DashboardSales from "../client/components/DashboardSales";
import CompanyService from "../../services/CompanyService";
import FloatingPanelService from "../shared/FloatingPanel";
import EditSegment from "./EditSegment";
import SegmentService from "../../services/SegmentService";
import { useEndpointData } from "../../utils/TableUtils";
import Loading from "../shared/components/Loading";
import ErrorMessage from "../shared/components/Error";
import PriorityService from "../../services/PriorityService";
import { getEnumArray } from "../../utils/EnumUtils";
import { CriterionModel, CriterionType, Operator } from "./providers/CriteriaContext";
import TooltipComponent from "../shared/TooltipComponent";
import { Switch } from "../../utils/Utils";
import BreadcrumbHistory from "../shared/BreadcrumbHistory";

export type SegmentClientListHistory = { segment: SegmentListResponse.Segment | undefined, folders: SegmentListResponse | undefined };
const SegmentClientList: React.FC = () => {    
    const { state, search } = useLocation<SegmentClientListHistory>();
    const history = useHistory();
    const transService = TranslationService.translate;
    const definitions = useMemo(() => SegmentService.getCriteria(transService), [transService]);
    const activities = useMemo(() => CompanyService.getActivityTypes().map(x => ({ label: x.ActivityTypeName, value: x.ActivityTypeID.toString() })), []);
    const id = state?.segment ? state.segment.SegmentID : Number(QueryString.parse(search, { ignoreQueryPrefix: true, parameterLimit: 3 })["segmentid"]);
    if (!Number.isInteger(id)) {
        window.location.href = `/${TranslationService.currentLanguage}/segment`;
    }
    const segmentPromise = useCallback(() => SegmentService.get(id), [id]);
    const foldersPromise = useCallback(() => (state?.folders?.list) ? Promise.resolve(state.folders) :
        SegmentService.getList(), [state?.folders]);

    const { loading: loadingSegment, error: errorSegment, response, reload } = useEndpointData(segmentPromise);
    const foldersResponse = useEndpointData(foldersPromise);

    const segmentFromList = useMemo(() => {
        if (foldersResponse.loading || loadingSegment || errorSegment || foldersResponse.error || foldersResponse?.response === undefined || response === undefined) {
            return undefined;
        }
        return foldersResponse.response.list.flatMap(x => x.items).find(y => y.SegmentID === response.item.SegmentID);
    }, [errorSegment, foldersResponse.error, foldersResponse.loading, foldersResponse.response, loadingSegment, response]);
    const resetSegment = () => {
        if (state.folders) {
            state.folders = undefined;
        }
        reload();
        foldersResponse.reload();
    };
    const pills = useMemo(() => {
        if (loadingSegment || errorSegment || response === undefined || response?.item === undefined) {
            return [];
        }
        const operators = getEnumArray(Operator);
        return response.item.Criteria.map(x => {
            const def = definitions.criterionList.find(y => y.id === x.Field);
            if (def === undefined) {
                return null;
            }
            const result = [def.name];
            const criterionIsNumber = [6, 19, 7, 20].includes(def?.id);
            const unit = Switch(
                [def.id === 31, "%"],
                [CriterionType.Number_Amount === def.type && !criterionIsNumber, " " + CompanyService.GetDefaultCurrencySymbol()],
                [CriterionType.Number_Amount === def.type && criterionIsNumber, " " + TranslationService.translate.Invoices.toLowerCase()],
                [CriterionType.Date === def.type, " " + TranslationService.translate.Days]) ?? "";
            const data: CriterionModel = x;
            if (![CriterionType.Client, CriterionType.Invoice, CriterionType.List].includes(def?.type)) {
                result.push(operators[data.Operator].text);
                if (CriterionType.ActivityType === def.type) {
                    result.push(data.Value + " " + TranslationService.translate.Days);
                    if (data.ActivityTypes) {
                        const operator = data.ActivityTypes[0] === "!" ? Operator.NotEqual : Operator.Equal;
                        result.push(TranslationService.translate.Activity + " - " + operators[operator].text);
                        result.push(data.ActivityTypes.split(",").map(y => y.replace("!", "")).map(y => activities.find(z => z.value === y)?.label).filter(x => x).join(", "));
                    }
                } else {
                    result.push(data.Value + unit);
                }
                return result;
            }
            if (def?.type !== CriterionType.List) {
                const fields = (def.type === CriterionType.Client ? definitions.clientDefs : definitions.invoiceDefs);
                const field = fields.find(x => x.AdditionalDefinitionID === data.Data)!;
                result.push(field.Name);
                if (field?.type !== CriterionType.List) {
                    if (data.Operator) {
                        result.push(operators[data.Operator].text);
                    }
                    result.push(data.Value + unit);
                    return result;
                }
                result.push(operators[data.Operator].text);
                if (data.Operator !== Operator.Empty) {
                    // TODO: Validar data value que viene como String en caso de algunos segmentos
                    result.push((data.IDs ?? data.Value).split(",")
                        .map(x => field.AdditionalDefinitionItems.find(y => y.AdditionalDefinitionItemID.toString() === x)?.Value).join(", "));
                }
            }
            return result;
        }).filter(x => x).map(x => x!.join(" - "));
    }, [activities, definitions.clientDefs, definitions.criterionList, definitions.invoiceDefs, errorSegment, loadingSegment, response]);
    if (loadingSegment || response === undefined) {
        return (<Loading />);
    }
    if (errorSegment) {
        return (<ErrorMessage onRefresh={resetSegment} message={TranslationService.translate.ErrorLoadingList} />);
    }
    const onEditSegment = () =>
        FloatingPanelService.showPanel({
            children: <EditSegment id={id} folders={foldersResponse.response} reload={resetSegment}
                onDeleteEvent={() => history.replace(`/${TranslationService.currentLanguage}/segment`)} />,
            title: TranslationService.translate.EditCustomersSegment,
            width: 1020,
            height: 700,
        });
    const data = response.item;

    const tableHead: TableHeader[] = [
        new TableHeader("name", TranslationService.translate.Client, false, true, "w-400px"),
        new TableHeader("cant", TranslationService.translate.Invoices, true, true, "w-100px"),
        new TableHeader("amount", TranslationService.translate.Due, true, true, "w-150px"),
        new TableHeader("amount", TranslationService.translate.NotDue, true, false, "w-150px"),
        new TableHeader("amount", TranslationService.translate.Amount, true, false, "w-150px"),
        new TableHeader("due", "", false, false, "w-200px"),
        new TableHeader("lastlog", TranslationService.translate.LastActivity, false, true, "w-300px"),
        new TableHeader("rank", TranslationService.translate.Priority, true, true, "w-80px"),
    ];
    const priority = <span className={"ms-1 priority priority-" + PriorityService.getRankingIndex(data.Priority)} style={{ fontSize: 28 }}>{data.Priority}</span>;

    return (
        <TabPosition module="segment">
            <ClientTableProvider segmentId={id}>
                <div className="container-fluid padding">
                    <BreadcrumbHistory current={{ name: data?.Name ?? "", link: `segment/details?segmentid=${id}` }}
                        defaultPrevious={{ name: TranslationService.translate.Segments, link: "segment" }} />
                    {CompanyService.canDo("editsegment") ?
                        <div className="show-child-on-hover pointer" onClick={onEditSegment}>
                            <h3 className="h3 mb-3">
                                {data.Name} {CompanyService.getSetting("enablesegmentpriority") && priority} <TooltipComponent title={TranslationService.translate.Edit}><i className="display-when-hovering-parent far fa-pen ms-1" style={{ color: "var(--intiza-blue)", fontSize: 20 }} /></TooltipComponent>
                            </h3>
                        </div>
                        : <h3 className="h3 mb-3">{data.Name} {priority} </h3>
                    }
                    <div className="chipsRowFilter">
                        {pills.map(x =>
                            <button type="button" className="btn chipItem"
                                style={{ cursor: "default", userSelect: "text" }} key={x}>
                                {x}
                            </button>)}
                    </div>
                    {data.Segment_Action.length > 0 && <>
                        <b className="mb-3">{TranslationService.translate.ActionsAssociated}:</b>
                        <div className="chipsRowFilter">
                            {data.Segment_Action.map(x =>
                                <button type="button" className="btn chipItem"
                                    style={{ cursor: "default", userSelect: "text" }} key={x.Id}>
                                    {x.Value}
                                </button>)}
                        </div>
                    </>}
                    {segmentFromList && <div className="mb-3">
                        <DashboardSales noDue={segmentFromList.amount && segmentFromList.overdueAmount ? segmentFromList.amount - segmentFromList.overdueAmount : segmentFromList.amount}
                            overdue={segmentFromList.overdueAmount} totalAmount={segmentFromList.amount} />
                    </div>}
                    <div className="card">
                        <Table headers={tableHead} item={ClientTableItem}>
                            <ClientTableTotal />
                        </Table>
                    </div>
                </div>
            </ClientTableProvider>
        </TabPosition>
    );
};

export default SegmentClientList;